import React, { useEffect, useState } from 'react';
import { usePreviousValue } from 'hooks/usePreviousProps';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Select from 'components/ui-components-v2/Select';
import Setup from 'components/data/Setup';
import '../styles/main.scss';

interface Props {
    value: any;
    options: { [key: string]: string } | { key: string; value: string }[] | string[];
    endAdornment?: string;
    sortOptions?: boolean;
    onMutation: (value: any) => void;
    resources?: string;
    setupField?: string;
    setFirstOption?: boolean;
    showEmptyOption?: boolean;
    readOnly?: boolean;
}

/**
 * SelectInput
 * Shows a selectbox the end user.
 * Outputs the value via onMutation.
 */
const SelectInput = (props: Props) => {
    const { value, options, endAdornment, showEmptyOption, setFirstOption, resources, setupField, readOnly, sortOptions, onMutation } = props;
    const [localValue, setLocalValue] = useState<any>(value);
    const prevProps = usePreviousValue<Props>(props);

    // If the value changes from outside set the local value
    useEffect(() => {
        if (props.value !== prevProps?.value) setLocalValue(value);
    }, [props.value]);

    // If there is no correct value set the first value
    useEffect(() => {
        const newOptions = getOptions();
        const hasNoValue = !value && setFirstOption;
        const hasNoCorrectValue = value && !newOptions.find((option) => option.key === value);
        if ((hasNoValue || hasNoCorrectValue) && newOptions.length) {
            const firstOption = newOptions[0].key;
            onMutation(firstOption);
        }
    }, []);

    /**
     * Makes sure all options are formatted the same way
     * @returns Formatted options
     */
    const getOptions = (): { key: string; value: string }[] => {
        let newOptions = options;
        // Load the options from resources
        if (resources) {
            newOptions = props[resources];
        }
        // Load the options from the setupfield
        else if (setupField) {
            newOptions = { '': '', ...Setup.get(props.setupField) };
        }

        if (!newOptions) {
            return [];
        }

        let formattedOptions: { key: string; value: string }[] = [];
        if (newOptions instanceof Array) {
            if (typeof newOptions[0] === 'string') {
                newOptions.forEach((option) => {
                    formattedOptions.push({ key: option, value: option });
                });
            } else {
                newOptions.forEach((option) => {
                    formattedOptions.push(option);
                });
            }
        } else {
            formattedOptions = Object.keys(newOptions).map((key) => ({ key, value: newOptions[key] }));
        }

        // Add the empty option to the top of the list
        if (showEmptyOption) {
            formattedOptions = [{ key: '', value: 'Select...' }, ...formattedOptions];
        }

        if (sortOptions) {
            formattedOptions.sort((a, b) => {
                const aKeyAsNumber = parseInt(a.key, 10);
                const bKeyAsNumber = parseInt(b.key, 10);
                const aIsNumber = !isNaN(aKeyAsNumber);
                const bIsNumber = !isNaN(bKeyAsNumber);

                if (aIsNumber && !bIsNumber) return -1;
                if (!aIsNumber && bIsNumber) return 1;
                // Convert keys to numbers and compare
                return aKeyAsNumber - bKeyAsNumber;
            });
        }
        return formattedOptions;
    };

    /**
     * Value changed
     * Send to onMutation
     */
    const onValueChanged = (event) => {
        setLocalValue(event.target.value);
        onMutation(event.target.value);
    };

    const newOptions = getOptions();

    // Display only
    if (readOnly) {
        const displayValue: string = (localValue && newOptions.find((displayOption) => displayOption.key === localValue)?.value) || '';

        return <React.Fragment>{displayValue}</React.Fragment>;
    }
    // Show select
    else {
        return (
            <Select value={localValue} onChange={onValueChanged} fullWidth size="medium" margin="dense" key={localValue}>
                {newOptions.map((displayOption) => (
                    <MenuItem className="input__select__option" dense value={displayOption.key} key={displayOption.key}>
                        {displayOption.value}
                        {endAdornment ?? ''}
                    </MenuItem>
                ))}
            </Select>
        );
    }
};

export default SelectInput;
