import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import { InputAdornment } from '@mui/material';
import Icon from 'components/ui-components-v2/Icon';
import Slider from 'components/ui-components-v2/Slider';

/**
 * SliderInput
 * Displays a slider with a min and max range
 */
export default class SliderInput extends Component {
    static propTypes = {
        /** Function which gets called when the switch has changed */
        onMutation: PropTypes.func,
        /** Current value of the slider */
        value: PropTypes.number,
        /** The minimum range to be displayed in the slider */
        min: PropTypes.number,
        /** The maximum range to be displayed in the slider */
        max: PropTypes.number,
        /** The defaultValue of the slider */
        defaultValue: PropTypes.number,
        /** The endAdornment of the slider */
        endAdornment: PropTypes.string
    };

    static defaultProps = {
        onMutation: () => { },
        min: 1,
        max: 100,
        value: null,
        defaultValue: 50,
        endAdornment: null
    };

    constructor(props) {
        super(props);

        this.state = {
            value: props.value !== undefined && props.value !== null ? props.value : props.defaultValue
        };
    }

    /**
     * Change slider value
     */
    onChange = (event, value) => {
        const { allowZero } = this.props;

        // Set value in state
        let val = parseInt(value);
        if (event.target.value) {
            val = parseInt(event.target.value);
        }
        if (!val) {
            if (allowZero) {
                val = 0;
            } else {
                val = 1;
            }
        }
        this.setState({ value: val });

        // Update after 500ms in case no new value is set
        clearTimeout(this.timeout);
        this.timeout = setTimeout(this.startMutate, 500);
    };

    /**
     * Component updates
     * In case the value changes, set new value
     */
    componentDidUpdate = (prevProps) => {
        const { value } = this.props;

        if (typeof value === 'number' && prevProps.value !== this.props.value) {
            this.setState({ value: value });
        }
    };

    /**
     * Start mutation
     */
    startMutate = () => {
        this.props.onMutation(this.state.value);
    };

    render() {
        const { label, value } = this.state;
        const { min, max, valueLabelDisplay = 'off', inputFieldDisplay, icon, step = 1, marks = false, allowZero, endAdornment } = this.props;

        let minValue = min;
        if (!minValue && allowZero) {
            minValue = 0;
        }
        //Check if location is Template Designer
        if (inputFieldDisplay) {
            return (
                <Grid container spacing={2} alignItems="center">
                    {icon && (
                        <Grid item>
                            <Icon>{icon}</Icon>
                        </Grid>
                    )}

                    <Grid item xs>
                        <Slider
                            min={parseInt(minValue)}
                            max={parseInt(max)}
                            step={parseInt(step)}
                            marks={marks}
                            aria-label={label}
                            value={value}
                            onChange={this.onChange}
                            valueLabelDisplay={valueLabelDisplay}
                        />
                    </Grid>
                    <Grid item>
                        <OutlinedInput
                            style={{ minWidth: 100 }}
                            value={value}
                            margin="dense"
                            onChange={this.onChange}
                            inputProps={{
                                min: minValue,
                                max: max,
                                type: 'number',
                                step: parseInt(step)
                            }}
                            endAdornment={endAdornment ? <InputAdornment position="end">{endAdornment}</InputAdornment> : undefined}
                        />
                    </Grid>
                </Grid>
            );
        } else {
            return (
                <Slider
                    min={minValue}
                    max={max}
                    step={step}
                    marks={marks}
                    aria-label={label}
                    value={value}
                    onChange={this.onChange}
                    valueLabelDisplay={valueLabelDisplay}
                />
            );
        }
    }
}
